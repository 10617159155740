
export default {
    columns:[
        {
            title: '头像',
            dataIndex: 'Avatar',
            key: 'Avatar',
            scopedSlots: { customRender: 'Avatar' },
        },
        {
            title: '笔名',
            dataIndex: 'PenName',
            key: 'PenName',
        },
        {
            title: '手机号码',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: '文本',
            dataIndex: 'Data',
            key: 'Data',
        },
        {
            title: '新增时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
