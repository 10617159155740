<template>
  <div class="ObjectMain">
    <div class="title">{{ data.Title }}</div>

    <div class="start">
      <a-tag color="red" v-if="data.Start==0">活动已关闭</a-tag>
      <a-tag color="green" v-if="data.Start==1">活动已开启</a-tag>
      <a-button type="link" @click="Upstart(1)" v-if="data.Start==0">开启活动</a-button>
      <a-button type="link" @click="Upstart(0)" v-if="data.Start==1">关闭活动</a-button>
    </div>

    <div class="Examine">
      <a-tag color="red" v-if="data.Examine==0">审核已关闭</a-tag>
      <a-tag color="green" v-if="data.Examine==1">审核已开启</a-tag>
      <a-button type="link" @click="Upexamine(1)" v-if="data.Examine==0">开启审核</a-button>
      <a-button type="link" @click="Upexamine(0)" v-if="data.Examine==1">关闭审核</a-button>
    </div>

    <div class="CanvasNumber">
      画板编号：<span class="number">{{ data.CanvasNumber }}</span>
      <a-button type="link" @click="addCanvas">新增画板</a-button>
    </div>


    <div class="info">
      <div class="block">参与活动人数：<span class="number">{{ data.UserNumber }}人</span></div>
      <div class="block">作品数：<span class="number">{{ data.WorkNumber }}个</span></div>
      <div class="block">开始时间：<span class="number">{{ data.TimeStart }}</span></div>
      <div class="block">结束时间：<span class="number">{{ data.TimeEnd }}</span></div>
      <div class="block">添加时间：<span class="number">{{ data.NewTime }}</span></div>
      <div class="block">流量：<span class="number">{{ data.Pv }}</span></div>
    </div>

    <div class="BgImg">
      <div>背景图</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="upBgImg"
          class="avatar-uploader"
      >
        <img v-if="data.BgImg" :src="data.BgImg" alt="" style="width: 100px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
    </div>

    <div class="OpenImg">
      <div>开屏图</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="upOpenImg"
          class="avatar-uploader"
      >
        <img v-if="data.OpenImg" :src="data.OpenImg" alt="" style="width: 100px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>

    </div>


    <div class="ReturnImg">
      <div>返回按钮 icon</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddReturnImg"
          class="avatar-uploader"
      >
        <img v-if="data.ReturnImg" :src="data.ReturnImg" alt="" style="width: 50px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
    </div>
    <div class="UserImg">
      <div>个人中心 icon</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddUserImg"
          class="avatar-uploader"
      >
        <img v-if="data.UserImg" :src="data.UserImg" alt="" style="width: 50px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
    </div>
    <div class="MapImg">
      <div>认领坐标 icon</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddMapImg"
          class="avatar-uploader"
      >
        <img v-if="data.MapImg" :src="data.MapImg" alt="" style="width: 50px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传图片
          </div>
        </div>
      </a-upload>
    </div>
    <div class="RenderingImg">
      <div>效果图</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddRendering"
          class="avatar-uploader"
      >
        <img v-if="data.RenderingImg" :src="data.RenderingImg" alt="" style="width: 100px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传效果图
          </div>
        </div>
      </a-upload>
    </div>
    <div class="PosterImage">
      <div>分享页海报底图</div>
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddPoster"
          class="avatar-uploader"
      >
        <img v-if="data.PosterImage" :src="data.PosterImage" alt="" style="width: 100px;display: block">
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传分享页海报底图
          </div>
        </div>
      </a-upload>
    </div>
  </div>
</template>

<script>
import {findDetails, UpIcon, UpStart, UpExamine, UpOpenImg, UpBgImg, AddCanvas} from "@/api/Admin/Activity";

export default {
  mounted() {
    this.getdata()
  },
  data() {
    return {
      ImageUrl: process.env.VUE_APP_URL + 'admin/Activity/image',
      data: {},
      loading: false,
    }
  },
  methods: {
    addCanvas() {
      let that = this
      let ActivityID = this.$route.query.Id
      this.$confirm({
        title: '是否确认新增画板吗?',
        content: () => <div style="color:red;">该操作不能撤销，请确认画板坐标使用完毕</div>,
        onOk() {
          AddCanvas({id: ActivityID}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    AddRendering(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upicon(url, '效果图')
        }
      }
    },
    AddPoster(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upicon(url, '海报底图')
        }
      }
    },
    Upexamine(Start) {
      let ActivityID = this.$route.query.Id
      let that = this
      this.$confirm({
        title: '是否确认执行操作吗?',
        onOk() {
          UpExamine({ActivityID, Start}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    Upstart(Start) {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ActivityID = this.$route.query.Id
      let that = this
      this.$confirm({
        title: '是否确认执行操作吗?',
        onOk() {
          UpStart({CompanyID, ActivityID, Start}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    AddReturnImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upicon(url, '返回按钮')
        }
      }
    },
    //更新背景图
    upBgImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let Url = response.payload
          let ActivityID = this.$route.query.Id

          UpBgImg({ActivityID, Url}).then(res => {
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      }
    },
    //更新开屏图
    upOpenImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let Url = response.payload
          let ActivityID = this.$route.query.Id

          UpOpenImg({ActivityID, Url}).then(res => {
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      }
    },
    AddUserImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upicon(url, '个人中心')
        }
      }
    },
    AddMapImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.upicon(url, '认领坐标')
        }
      }
    },
    upicon(Url, Class) {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ActivityID = this.$route.query.Id
      UpIcon({CompanyID, ActivityID, Url, Class}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success('上传成功');
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    getdata() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ActivityID = this.$route.query.Id
      findDetails({CompanyID, ActivityID}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          let NewTime = data.NewTime
          data.NewTime = new Date(NewTime).getFullYear() + '-' + (new Date(NewTime).getMonth() + 1) + '-' + new Date(NewTime).getDate()
          let TimeStart = data.TimeStart
          data.TimeStart = new Date(TimeStart).getFullYear() + '-' + (new Date(TimeStart).getMonth() + 1) + '-' + new Date(TimeStart).getDate()
          let TimeEnd = data.TimeEnd
          data.TimeEnd = new Date(TimeEnd).getFullYear() + '-' + (new Date(TimeEnd).getMonth() + 1) + '-' + new Date(TimeEnd).getDate()

          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.ObjectMain {
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  position: relative;

  .logo {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 35px;
    left: 20px;

    .avatar {
      width: 80px;
      height: 80px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .EditCover {
    position: absolute;
    left: 190px;
    top: 160px;
  }

  .cover {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 170px;
    left: 120px;
  }

  .ReturnImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 380px;
  }

  .UserImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 480px;
  }

  .MapImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 580px;
  }

  .RenderingImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 750px;
  }

  .PosterImage{
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 880px;
  }

  .OpenImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 250px;
  }

  .BgImg {
    font-size: 13px;
    color: #999999;
    position: absolute;
    top: 100px;
    left: 120px;
  }

  .coverMain {
    width: 300px;
    height: 150px;
    position: absolute;
    top: 210px;
    left: 120px;

    img {
      width: 300px;
      height: 150px;
    }
  }

  .title {
    position: absolute;
    left: 120px;
    top: 30px;
    color: #666666;
    font-weight: bold;
    font-size: 18px;
  }

  .start {
    position: absolute;
    left: 290px;
    top: 30px;
    color: #666666;
    font-weight: bold;
    font-size: 18px;
  }

  .Examine {
    position: absolute;
    left: 490px;
    top: 30px;
    color: #666666;
    font-weight: bold;
    font-size: 18px;
  }

  .examine {
    position: absolute;
    left: 340px;
    top: 35px;
    color: #aaaaaa;

    .icon {
      color: #58b99d;
    }
  }

  .CanvasNumber {
    position: absolute;
    left: 680px;
    top: 30px;

    .number {
      font-weight: bold;
    }
  }

  .info {
    position: absolute;
    left: 120px;
    top: 65px;
    color: #aaaaaa;
    font-size: 13px;

    .block {
      display: inline-block;
      margin-right: 15px;

      .number {
        margin-left: 5px;
        color: #666666;
      }
    }
  }

  .operate {
    position: absolute;
    left: 120px;
    top: 100px;

    .button {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

//.ant-upload .ant-upload-select-picture-card ::v-deep{
//  width: 80px;
//  height: 80px;
//}


.avatar-uploader ::v-deep .ant-upload {
  width: 80px;
  height: 80px;
  border: none;
  background: none;
}

.coverImage ::v-deep .ant-upload {
  width: 300px;
  height: 150px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  //text-align: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /*垂直居中*/
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}


</style>
