import request from '../../utils/request';

//查询活动开启并获取活动ID
export const findActivity = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Prize/WriteOff/Activity/find',
        method: 'get',
        params: query
    });
};
//查询奖品彩蛋
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Prize/WriteOff/find',
        method: 'get',
        params: query
    });
};

//搜索奖品彩蛋
export const findSearch = query => {
    return request({
        url: process.env.VUE_APP_URL  + 'Admin/Prize/WriteOff/find/search',
        method: 'get',
        params: query
    });
};
//核销奖品
export const writeOffPrze = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Prize/WriteOff/writeOff',
        method: 'post',
        data: query
    });
};
