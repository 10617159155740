<template>
  <div class="MapEffect">
    <div class="navOperate">
      <a-button @click="init">生成效果图</a-button>
      <a-select class="select" @change="handleChange">
        <a-select-option :value="item.value" v-for="(item,index) in CanvasNumber" :key="index">
          {{ item.text }}
        </a-select-option>
      </a-select>
    </div>
    <div class="bg">
      <canvas ref="canvas" id="imageWrapper" style="width: 100%;height: 100%"></canvas>
    </div>
    <Load ref="Load"></Load>
  </div>
</template>

<script>

import Load from './com/load.vue'
import {Canvasbase64, FindActivity, MapEffectFind} from "@/api/Admin/ActivityDetails/Work";

export default {
  data() {
    return {
      CreationSizeX: 350,
      CreationSizeY: 540,
      data: [],
      CanvasNumber: [],
      InCanvasNumber: ''
    }
  },
  mounted() {
    let ActivityID = this.$route.query.Id
    FindActivity({ActivityID}).then(res => {
      let payload = res.payload
      let CanvasNumber = payload.CanvasNumber

      let add = []

      for (let i = 1; i <= CanvasNumber; i++) {
        add.push({
          value: i,
          text: '活动画板：' + i + '号'
        })
      }
      this.CanvasNumber = add
    })
  },
  methods: {
    handleChange(value) {
      this.InCanvasNumber = value
    },
    init() {
      let CompanyID = this.$store.state.user.info.CompanyID
      let ActivityID = this.$route.query.Id
      let InCanvasNumber = this.InCanvasNumber

      if (!InCanvasNumber) {
        this.$message.error('请选择画板编号！');
        return;
      }

      MapEffectFind({CompanyID, ActivityID, CanvasNumber: InCanvasNumber}).then(res => {
        let payload = res.payload
        let BgImg = payload.BgImg

        let canvas = this.$refs.canvas
        let crx = canvas.getContext('2d')

        let x = payload.CanvasNumberX * 360
        let y = payload.CanvasNumberY * 540
        canvas.width = x
        canvas.height = y
        let data = payload.data
        this.data = data

        Canvasbase64({url: BgImg}).then(res => {
          let data = res.payload
          let img = new Image()
          img.src = data
          img.onload = () => {
            crx.drawImage(img, 0, 0, x, y)
          }
          this.initCanvas()
        })
      })
    },
    async initCanvas() {
      this.$refs.Load.open()
      const {data} = this
      // let num = 0
      for (let i in data) {
        let ImageUrl = data[i].ImageUrl
        if (!this.checkFileFormat(ImageUrl)) {
          await this.base64Imgs(i)
        }
      }
      this.$refs.Load.close()
    },
    //加载背景的图案
    base64Imgs(i) {
      return new Promise((resolve) => {
        const {data} = this
        let imgdata = data[i]
        let bgImg = imgdata.ImageUrl
        let canvas = this.$refs.canvas
        let crx = canvas.getContext('2d')
        let num = JSON.parse(JSON.stringify(this.data.length))
        let numss = JSON.parse(JSON.stringify(i))
        this.$refs.Load.texts(`${numss}/${num}`)
        Canvasbase64({url: bgImg}).then(res => {
          let img = new Image()
          if (res.payload) {

            this.TimeIn = setTimeout(() => {
              resolve()
            }, 2000)
            img.src = res.payload
            img.onload = () => {
              crx.drawImage(img, (imgdata.X - 1) * this.CreationSizeX, (imgdata.Y - 1) * this.CreationSizeY, this.CreationSizeX, this.CreationSizeY)
              clearTimeout(this.TimeIn)
              resolve()
            }
            img.onError = () => {
              resolve()
            }

          } else {
            resolve()
          }
        })
      })
    },
    checkFileFormat(filename) {
      var regex = /\.heic$/i; // i 标志表示不区分大小写

      return regex.test(filename);
    },
    Bas64Image() {
      // Canvasbase64().then(res=>{
      //
      // })
    }
  },
  components: {Load}
}
</script>


<style scoped lang="scss">
.MapEffect {
  width: 100%;
  height: 600px;
  overflow-y: auto;
  background-color: #ffffff;

  .navOperate {
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-indent: 2rem;
    position: relative;

    .select {
      width: 180px;
      margin-left: 20px;
      position: absolute;
      left: 150px;
      top: 25px;
    }
  }

  .bg {
    margin: 0 auto;
    width: 900px;
    height: 500px;
  }
}
</style>