var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加活动项目","visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"活动主题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Title',
                          {  rules: [{ required: true, message: '请输入活动主题' }] }
           ]),expression:"[\n                          'Title',\n                          {  rules: [{ required: true, message: '请输入活动主题' }] }\n           ]"}],attrs:{"placeholder":"请输入活动主题"}})],1),_c('a-form-item',{attrs:{"label":"活动编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'TitleNumber',
                          {  rules: [{ required: true, message: '请输入活动编号' }] }
           ]),expression:"[\n                          'TitleNumber',\n                          {  rules: [{ required: true, message: '请输入活动编号' }] }\n           ]"}],attrs:{"placeholder":"请输入活动编号"}})],1),_c('a-form-item',{attrs:{"label":"开屏页"}},[(_vm.OpenImg)?_c('img',{staticStyle:{"width":"200px","display":"block"},attrs:{"src":_vm.OpenImg,"alt":""}}):_vm._e(),_c('a-upload',{attrs:{"name":"file","multiple":false,"action":_vm.ImageUrl,"show-upload-list":false,"headers":{
                'X-Requested-With':null
            }},on:{"change":_vm.AddImageOpenImg}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传开屏页 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"画板背景图"}},[(_vm.BgImg)?_c('img',{staticStyle:{"width":"200px","display":"block"},attrs:{"src":_vm.BgImg,"alt":""}}):_vm._e(),_c('a-upload',{attrs:{"name":"file","multiple":false,"action":_vm.ImageUrl,"show-upload-list":false,"headers":{
                'X-Requested-With':null
            }},on:{"change":_vm.AddImageBgImg}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传画板背景图 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"开始日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'TimeStart',
                          {  rules: [{ required: true, message: '请选择开始日期' }] }
           ]),expression:"[\n                          'TimeStart',\n                          {  rules: [{ required: true, message: '请选择开始日期' }] }\n           ]"}]})],1),_c('a-form-item',{attrs:{"label":"结束日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'TimeEnd',
                          {  rules: [{ required: true, message: '请选择结束日期' }] }
           ]),expression:"[\n                          'TimeEnd',\n                          {  rules: [{ required: true, message: '请选择结束日期' }] }\n           ]"}]})],1),_c('a-form-item',{attrs:{"label":"画板x数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'CanvasNumberX',
                          {  rules: [{ required: true, message: '请输入画板x数量' }] }
           ]),expression:"[\n                          'CanvasNumberX',\n                          {  rules: [{ required: true, message: '请输入画板x数量' }] }\n           ]"}],attrs:{"min":1}})],1),_c('a-form-item',{attrs:{"label":"画板Y数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'CanvasNumberY',
                          {  rules: [{ required: true, message: '请输入画板Y数量' }] }
           ]),expression:"[\n                          'CanvasNumberY',\n                          {  rules: [{ required: true, message: '请输入画板Y数量' }] }\n           ]"}],attrs:{"min":1}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }