
export default {
    columns:[
        {
            title: '头像',
            dataIndex: 'Avatar',
            key: 'Avatar',
            scopedSlots: { customRender: 'Avatar' },
        },
        {
            title: '笔名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '作品数',
            key: 'WorkNumber',
            dataIndex: 'WorkNumber',
        },
        {
            title: '加入时间',
            key: 'Newtime',
            dataIndex: 'Newtime',
        },
        {
            title: '登录时间',
            key: 'LoginTime',
            dataIndex: 'LoginTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
