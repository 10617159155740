
export default {
    columns:[
        {
            title: '画板编号',
            dataIndex: 'CanvasNumber',
            key: 'CanvasNumber',
        },
        {
            title: '作品编号',
            key: 'WorkNumber',
            dataIndex: 'WorkNumber',
        },
        {
            title: '用户名',
            key: 'UserName',
            dataIndex: 'UserName',
        },
        {
            title: '作品昵称',
            key: 'PenName',
            dataIndex: 'PenName',
        },
        {
            title: '作品图片',
            key: 'ImageUrl',
            dataIndex: 'ImageUrl',
            scopedSlots: { customRender: 'ImageUrl' },
        },
        {
            title: '坐标',
            key: 'XY',
            dataIndex: 'XY',
        },
        {
            title: '点赞数',
            key: 'CollectNumber',
            dataIndex: 'CollectNumber',
        },
        {
            title: '新增时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
