<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">

        <span slot="InShow" slot-scope="tags">
        <a-badge v-if="tags=='待审批'" status="warning" text="待审批"/>
          <a-badge v-if="tags=='审批未通过'" status="error" text="审批未通过"/>
          <a-badge v-if="tags=='审批通过'" status="success" text="审批通过"/>
    </span>


      <span slot="Operate" slot-scope="tags,text">
        <a-button type="link" class="green" @click="okObject(text)" v-if="text.InShow=='待审批'">通过</a-button>
        <a-button type="link" class="red" @click="noObject(text)"  v-if="text.InShow=='待审批'">不通过</a-button>
      </span>
    </a-table>

  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fail, fetchData, pass} from "@/api/Enterprise/ExamineCompany";


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    okObject(value) {
      let id = value.id
      let that = this

      this.$confirm({
        title: '是否确认通过吗?',
        onOk() {
          pass({id}).then(res=>{
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    noObject(value) {
      let id = value.id
      let that = this
      this.$confirm({
        title: '是否确认不通过吗?',
        onOk() {
          fail({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let Current = this.pagination.current
      fetchData({Current}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = data

          this.pagination.total = res.payload.total
        } else {
          true.$message.error(res.errorMsg);
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.col-sql {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
}
</style>
