import request from '../utils/request';

//查询当前活动数据
export const initActivity = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Visualization/initActivity',
        method: 'get',
        params: query
    });
};


//查询今日作品数据
export const newDateWork = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Visualization/newDateWork',
        method: 'get',
        params: query
    });
};

//查询今日作品数据
export const newDateUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Visualization/newDateUser',
        method: 'get',
        params: query
    });
};