<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="mark">
      <a-button class="button" @click="batchmark('正常')">批量标记正常</a-button>
      <a-button class="button" @click="batchmark('违规')">批量标记违规</a-button>

      <a-switch checked-children="开启自动刷新" un-checked-children="关闭自动刷新" class="switch" @change="onChange"/>
      <span>刷新时间</span>
      <a-input-number class="input" addon-before="刷新时间" addon-after="s" v-model="refreshtime"/>
      <span>ms</span>

      <a-button class="button" @click="getdata" type="primary">手动刷新</a-button>
    </div>
    <a-table :columns="columns" :data-source="data"
             :rowKey="(record, index) => { return index }"
             class="table"
             :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
             :scroll="{ y: 600 }"
             :loading="loading">
        <span slot="Avatar" slot-scope="tags,record">
          <a-avatar :size="30" icon="user" shape="square" :src="record.Avatar"/>
        </span>
      <span slot="Operate" slot-scope="text,record">
        <a-button type="link" @click="mark(record.id,'正常')" class="green">标记正常</a-button>
        <a-button type="link" @click="mark(record.id,'违规')" class="red">标记违规</a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fetchData, setbatchmark, setMark} from "@/api/Examine/Evaluate";

let {columns} = indexTest

export default {
  data() {
    return {
      refresh: false,
      refreshtime: 1000,
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      selectedRowKeys: []
    }
  },
  watch: {
    refresh(value) {
      if (value) {
        this.openRefresh()
      } else {
        this.closeRefresh()
      }
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    onChange(checked) {
      this.refresh = checked
    },
    openRefresh() {
      this.set = setInterval(() => {
        this.getdata()
      }, this.refreshtime)
    },
    closeRefresh() {
      clearInterval(this.set)
    },
    batchmark(InShow) {
      let selectedRowKeys = this.selectedRowKeys
      if (selectedRowKeys.length == 0) {
        this.$message.error('请选择弹幕');
        return
      }
      let addData = []
      let data = this.data
      for (let i in selectedRowKeys) {
        let k = selectedRowKeys[i]
        addData.push({
          id: data[k].id,
        })
      }
      setbatchmark({data: addData, InShow}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    mark(id, InShow) {
      setMark({id, InShow}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      this.selectedRowKeys = []
      let CompanyID = this.$store.state.user.info.CompanyID
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      this.data=[]
      fetchData({pageIndex, pageSize, CompanyID}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.pagination.total = res.payload.total
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    getRegexData() {
      this.loading = true
    },
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .mark {
    width: 100%;
    height: 90px;
    line-height: 90px;

    .button {
      margin-left: 15px;
      display: inline-block;
    }

    .switch {
      margin-left: 20px;
    }

    .input {
      display: inline-block;
      width: 100px;
      margin-left: 10px;
      margin-top: 29px;
    }

    span {
      margin-left: 10px;
    }
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}
</style>
