import request from '../../utils/request';

//查询未审核作品
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Examine/Comment/find',
        method: 'get',
        params: query
    });
};

//单个标记状态
export const setMark = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Examine/Comment/mark',
        method: 'post',
        data: query
    });
};

//批量标记状态
export const setbatchmark = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Examine/Comment/batchmark',
        method: 'post',
        data: query
    });
};