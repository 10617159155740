import LoginView from '@/pages/Login/index';
import Layout from '@/pages/Layout';
import SearchView from '@/pages/Admin/index';
import EnterpriseView from '@/pages/Enterprise/index'
import SetUpView from '@/pages/SetUp/index'
import AdminCompanyView from '@/pages/AdminCompany/index.vue'
import ExamineView from '@/pages/Examine/index.vue'
import PrizeView from '@/pages/Prize/index.vue'


const AdminTab = [
    {
        path: '/',
        name: '/',
        redirect: '/Visualization',
        component: Layout,
        children: [
            {
                path: '/Visualization',
                component: () => import('@/pages/Visualization'),
                meta: {
                    title: '数据大板'
                },
            },
            {
                path: '/Admin',
                redirect: '/Admin/Activity',
                component: SearchView,
                children: [
                    {
                        path: 'Activity',
                        component: () => import('@/pages/Admin/pages/Activity'),
                        meta: {
                            title: '活动列表'
                        },
                    },
                    {
                        path: 'Activity/Details',
                        component: () => import('@/pages/Admin/pages/ActivityDetails/index.vue'),
                        meta: {
                            title: '项目详情'
                        },
                    },
                    {
                        path: 'User',
                        component: () => import('@/pages/Admin/pages/User/index.vue'),
                        meta: {
                            title: '用户管理'
                        },
                    }
                ]
            }, {
                path: '/AdminCompany',
                redirect: '/AdminCompany/User',
                component: AdminCompanyView,
                children: [
                    {
                        path: 'User',
                        component: () => import('@/pages/AdminCompany/User/index.vue'),
                        meta: {
                            title: '企业管理员管理'
                        },
                    },
                ]
            },
            {
                path: '/Enterprise',
                redirect: '/Enterprise/Company',
                component: EnterpriseView,
                children: [
                    {
                        path: 'Company',
                        component: () => import('@/pages/Enterprise/Company/index.vue'),
                        meta: {
                            title: '企业管理'
                        },
                    },
                    {
                        path: 'AdminUser',
                        component: () => import('@/pages/Enterprise/AdminUser/index.vue'),
                        meta: {
                            title: '企业管理员管理'
                        },
                    },
                    {
                        path: 'ExamineCompany',
                        component: () => import('@/pages/Enterprise/ExamineCompany/index.vue'),
                        meta: {
                            title: '企业审核'
                        },
                    },
                    {
                        path: 'ExamineObject',
                        component: () => import('@/pages/Enterprise/ExamineObject/index.vue'),
                        meta: {
                            title: '项目审核'
                        },
                    },
                ]
            },
            {
                path: '/Examine',
                redirect: '/Examine/Work',
                component: ExamineView,
                children: [
                    {
                        path: 'Work',
                        component: () => import('@/pages/Examine/Work/index.vue'),
                        meta: {
                            title: '作品审核'
                        },
                    },
                    {
                        path: 'Comment',
                        component: () => import('@/pages/Examine/Comment/index.vue'),
                        meta: {
                            title: '弹幕审核'
                        },
                    },
                    {
                        path: 'Evaluate',
                        component: () => import('@/pages/Examine/Evaluate/index.vue'),
                        meta: {
                            title: '评论审核'
                        },
                    },
                ]
            },
            {
                path: '/Prize',
                redirect: '/Prize/WriteOff',
                component: PrizeView,
                children: [
                    {
                        path: 'WriteOff',
                        component: () => import('@/pages/Prize/WriteOff/index.vue'),
                        meta: {
                            title: '核销奖品'
                        },
                    }
                ]
            },
            {
                path: '/SetUp',
                redirect: '/SetUp/Info',
                component: SetUpView,
                children: [
                    {
                        path: 'Info',
                        component: () => import('@/pages/SetUp/page/Info/index.vue'),
                        meta: {
                            title: '账户信息'
                        },
                    },
                    {
                        path: 'Secure',
                        component: () => import('@/pages/SetUp/page/Secure/index.vue'),
                        meta: {
                            title: '账户安全'
                        },
                    },
                ]
            }
        ]
    }
]


const LoginTab = [
    {
        path: '/Login',
        component: LoginView,
        children: [],
        meta: {
            title: '万人添照信息管理系统'
        }
    },
]


const routes = [...AdminTab, ...LoginTab];

export default routes;