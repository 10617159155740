var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑彩蛋奖品' : '添加彩蛋奖品',"okText":"提交","visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"500px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 7 },"wrapper-col":{ span: 15 }}},[_c('a-form-item',{attrs:{"label":"奖品标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'Title',
                                  {  rules: [{ required: true, message: '请输入奖品标题'}], initialValue: _vm.isEdit ? _vm.commodityDetail.Title : '' },
                              ]),expression:"[\n                                  'Title',\n                                  {  rules: [{ required: true, message: '请输入奖品标题'}], initialValue: isEdit ? commodityDetail.Title : '' },\n                              ]"}],attrs:{"placeholder":"请输入奖品标题"}})],1),_c('a-form-item',{attrs:{"label":"中奖图片"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"headers":{
                'X-Requested-With':null
            },"name":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.ImageUrl},on:{"change":_vm.upBgImg}},[(_vm.Image)?_c('img',{staticStyle:{"width":"100px","display":"block"},attrs:{"src":_vm.Image,"alt":""}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上传图片 ")])],1)])],1),_c('a-form-item',{attrs:{"label":"作品数触发点"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'WorkNumber',
                                  {  rules: [{ required: true, message: '请输入作品数触发点' }], initialValue: _vm.isEdit ? _vm.commodityDetail.WorkNumber : 1 },
                              ]),expression:"[\n                                  'WorkNumber',\n                                  {  rules: [{ required: true, message: '请输入作品数触发点' }], initialValue: isEdit ? commodityDetail.WorkNumber : 1 },\n                              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入作品数触发点"}})],1),_c('a-form-item',{attrs:{"label":"兑换须知"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Notice',
                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: _vm.isEdit ? _vm.commodityDetail.Notice : '' },
                          ]),expression:"[\n                              'Notice',\n                              {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.Notice : '' },\n                          ]"}],attrs:{"placeholder":"请输入兑换须知","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }