
export default {
    columns:[
        {
            title: '派发机构',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
        },
        {
            title: '社区项目',
            key: 'ProjectName',
            dataIndex: 'ProjectName',
        },
        {
            title: '活动项目',
            key: 'ContName',
            dataIndex: 'ContName',
        },
        {
            title: '派发时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '贡献值',
            key: 'ToNumber',
            dataIndex: 'ToNumber',
            scopedSlots: { customRender: 'ToNumber' },
        }
    ]
}
