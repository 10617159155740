<template>
  <a-layout>
    <a-layout-sider class="sub-sider">
      <sub-sider :menu="menu" :title="menuTitle"/>
    </a-layout-sider>
    <a-layout-content>
      <content-header :title="contentTitle"/>
      <div class="content">
        <router-view></router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import SubSider from '@/components/SubSider.vue';
import ContentHeader from '@/components/ContentHeader.vue';

export default {
  components: {
    'sub-sider': SubSider,
    'content-header': ContentHeader,
  },
  data() {
    return {
      menuTitle: '奖品管理',
      contentTitle: "奖品管理",
      menu: [
        {
          icon:'align-right',
          title: '核销奖品',
          item_link:'/Prize/WriteOff'
        },
      ]
    };
  },
  methods: {},
}
</script>

<style scoped>

.sub-sider {
  overflow: auto;
  height: 100vh;
  background: #ebedf1;
}

.content {
  background: #f0f2f5;
  padding: 20px;
}

</style>