export default {
    columns: [
        {
            title: '机构/企业',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
        },
        {
            title: '注册手机',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: '管理员姓名',
            dataIndex: 'User',
            key: 'User',
        },
        {
            title: '法定代表人',
            dataIndex: 'UserName',
            key: 'UserName',
        },
        {
            title: '法定代表人联系方式',
            dataIndex: 'UserPhone',
            key: 'UserPhone',
        },
        {
            title: '工商注册号/社会信用代码',
            dataIndex: 'ThirdPartyUserId',
            key: 'ThirdPartyUserId',
        },
        {
            title: '状态',
            dataIndex: 'InShow',
            key: 'InShow',
            scopedSlots: {customRender: 'InShow'},
        },
        {
            title: '申请时间',
            key: 'Time',
            dataIndex: 'Time',
            width: 150
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: {customRender: 'Operate'},
        },
    ]
}
