<template>
  <div class="Distribution">
    <div class="topBlock">
      <a-input-search placeholder="请输入奖品标题" :loading="loading" enter-button class="search" v-model="searchInput"
                      @search="onSearch"/>
      <a-button @click="reset" class="reset">复位</a-button>
    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
        <span slot="InShow" slot-scope="tags,text">
          <a-tag v-if="text.InShow=='未中奖'" color="orange">未中奖</a-tag>
          <a-tag v-if="text.InShow=='已中奖'" color="green">已中奖</a-tag>
          <a-tag v-if="text.WriteOff=='未核销'" color="orange">未核销</a-tag>
          <a-tag v-if="text.WriteOff=='已核销'" color="green">已核销</a-tag>
        </span>
      <span slot="Image" slot-scope="text">
        <img :src="text" alt="" style="width: 70px" @click="openImage(text)" class="ImageUrl">
      </span>
      <span slot="Operate" slot-scope="text,record">
         <a-popover title="用户信息" v-if="record.InShow=='已中奖'">
        <template slot="content">
          <p>用户：{{ record.UserName }}</p>
          <p>联系方式：{{ record.UserPhone }}</p>
        </template>
        <a-button type="link">查看中奖用户</a-button>
      </a-popover>
           <a-button type="link" class="green" @click="WriteOffPize(record.id)"
                     v-if="record.WriteOff=='未核销'&&record.InShow=='已中奖'">核销奖品</a-button>
      </span>
    </a-table>
    <div class="load" v-if="loadFile">
      <div class="main">
        <a-spin class="spin"/>
        <p>处理数据中...</p>
      </div>
    </div>
    <LightBox :media="media" :closable="false" v-if="lightboxVisible"></LightBox>
    <div class="close" v-if="lightboxVisible" @click="closeImage">
      <img src="../../../../public/images/close.png" alt="">
    </div>
  </div>
</template>

<script>
import indexTest from "@/pages/Prize/WriteOff/moble/indexTest";
import {
  find,
  writeOffPrze,
  findActivity, findSearch
} from "@/api/Prize/WriteOff";
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      loadFile: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      InShow: '已中奖',
      media: [],
      lightboxVisible: false,
      searchInput: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    onSearch() {
      let searchInput = this.searchInput
      if (!searchInput) {
        this.$message.error('请输入奖品标题');
      }
      this.data = []
      this.loading = true
      let ActivityID = this.ActivityID
      findSearch({ActivityID, searchInput}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let payload = res.payload
          this.data = payload
          this.pagination.total = 1
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    reset() {
      this.searchInput = ''
      this.pagination.current = 1
      this.getdata()
    },
    init() {
      let CompanyID = this.$store.state.user.info.CompanyID
      findActivity({CompanyID}).then(res => {
        if (res.errorCode == 200) {
          let ActivityID = res.payload
          this.ActivityID = ActivityID
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    closeImage() {
      this.lightboxVisible = false
    },
    openImage(img) {
      this.media = [
        {
          thumb: img,
          src: img
        }
      ]
      this.lightboxVisible = true
    },
    WriteOffPize(id) {
      let that = this
      this.$confirm({
        title: '是否确认核销该奖品吗?',
        onOk() {
          writeOffPrze({id}).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let ActivityID = this.ActivityID
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      let InShow = this.InShow

      this.data = []
      find({ActivityID, pageIndex, pageSize, InShow}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let total = res.payload.total

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

            let UpTime = data[i].UpTime
            if (UpTime) {
              UpTime = new Date(UpTime);

              let timeHours = UpTime.getHours()//时
              let timeMinutes = UpTime.getMinutes()  //分
              let second = UpTime.getSeconds()

              if (timeHours < 10) {
                timeHours = '0' + timeHours
              }
              if (timeMinutes < 10) {
                timeMinutes = '0' + timeMinutes
              }
              if (second < 10) {
                second = '0' + second
              }

              data[i].UpTime = UpTime.getFullYear() + '-' + (UpTime.getMonth() + 1) + '-' + UpTime.getDate() + ' ' + timeHours + ':' + timeMinutes + ':' + second;
            }
          }

          this.data = data
          this.pagination.total = total
        } else {
          this.$message.error(res.errorMsg);
        }
      })

    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  },
  components: {LightBox}
}
</script>

<style scoped lang="scss">

.Distribution {
  width: 100%;
  height: 820px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 90px;
    position: relative;

    .search {
      position: absolute;
      width: 300px;
      left: 20px;
      top: 30px;
    }

    .reset {
      position: absolute;
      left: 370px;
      top: 30px;
    }
  }

  .mark {
    width: 100%;
    height: 50px;
    line-height: 50px;

    .button {
      display: inline-block;
      margin-left: 15px;
    }
  }

  .radio {
    margin-left: 15px;
  }
}

.table {
  width: 98%;
  margin: 0 auto;
  overflow-y: hidden;
  height: 750px;
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.load {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;

  .main {
    width: 200px;
    height: 200px;
    color: #f3f3f3;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
    text-align: center;
  }

  .spin {
    margin-bottom: 10px;
    //width: 100%;
    //height: 100%;
  }
}

.ImageUrl {
  cursor: pointer;
}

.close {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 50px;
  top: 50px;
  z-index: 10000;
  cursor: pointer;
  transition: transform 0.3s ease;

  img {
    width: 100%;
    height: 100%
  }
}

.close:hover {
  transform: rotate(90deg); /* 当鼠标悬停时应用旋转变换 */
}
</style>
