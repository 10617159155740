<template>
  <div class="load" v-if="load">
    <div class="ico"></div>
    <div class="text" v-if="look">正在生成预览图中，期间请勿操作</div>
    <div class="text" v-else>完成加载：（{{ text }}）</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      load: false,
      look: false,
      text: '0/0'
    }
  },
  methods: {
    texts(str) {
      return new Promise((resolve) => {
        this.text = str
        resolve()
      })
    },
    open(str) {
      this.load = true
      if (str) {
        this.look = true
      }
    },
    close() {
      this.text = '0/0'
      this.load = false
      this.look = false
    }
  }
}
</script>

<style scoped lang="scss">
.load {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10500;

  .ico {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    background-size: 100% 100%;
    background-image: url("../../../../../../../assets/load.png");
    transition: 0.5s;
    animation: rotate 1s linear infinite;
  }

  .text {
    color: #ffffff;
    position: absolute;
    width: 300px;
    height: 40px;
    font-size: 14px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: 30px;
    text-align: center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
</style>
