<template>
  <a-spin :spinning="load">
  <div class="main">
    <a-row>
      <a-col :span="6">
        <div class="block">
          <div class="title">今日作品量</div>
          <div class="data">{{data.WorkNew}}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="block">
          <div class="title">今日新增用户量</div>
          <div class="data">{{data.UserNew}}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="block">
          <div class="title">总作品量</div>
          <div class="data">{{data.WorkTol}}</div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="block">
          <div class="title">总用户量</div>
          <div class="data">{{data.UserTol}}</div>
        </div>
      </a-col>
    </a-row>
  </div>
  </a-spin>
</template>

<script>
import {initActivity} from "@/api/Visualization";

export default {
  data() {
    return {
      load: false,
      data:{}
    }
  },
  mounted() {
    let CompanyID = this.$store.state.user.info.CompanyID
    this.load = true
    initActivity({CompanyID}).then(res => {
      this.load = false
      if (res.errorCode == 200) {
        let payload = res.payload

        if (payload.start == '正常') {
          let data = payload.data
          this.data = data
        } else {
          this.$message.error(payload.start);
        }


      } else {
        this.$message.error(res.errorMsg);
      }
    })
  }
}


</script>

<style scoped lang="scss">
.main {
  width: 100%;
  height: 120px;
  margin-bottom: 20px;

  .block {
    width: 95%;
    height: 120px;
    background-color: #f9f9f9;
    border-radius: 15px;
    border: 1px solid #e9e9e9;
    margin: 0 auto;

    .title {
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      height: 60px;
      line-height: 60px;
    }

    .data {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 15px;
    }
  }
}
</style>