<template>
<div class="Visualization">
  <Main></Main>
  <Work></Work>
  <User></User>
</div>
</template>

<script>
import Work from './com/Work.vue'
import Main from './com/Main.vue'
import User from './com/User.vue'

export default {
  components:{
    Work,Main,User
  }
}

</script>

<style scoped lang="scss">
.Visualization{
  width: 100%;
  height: 900px;
  background-color: #ffffff;
  padding: 20px;
  overflow-y: auto;
}
</style>
