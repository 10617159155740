import request from '../../utils/request';

//查询成员
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/find',
        method: 'get',
        params: query
    });
};

//添加活动项目
export const addActivity = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/add',
        method: 'post',
        data: query
    });
};

//查询活动项目基本信息
export const findDetails = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/find/Details',
        method: 'get',
        params: query
    });
};

//更新icon
export const UpIcon = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/UpIcon',
        method: 'post',
        data: query
    });
};

//更新背景图
export const UpBgImg = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/UpBgImg',
        method: 'post',
        data: query
    });
};

//更新开屏图
export const UpOpenImg = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/UpOpenImg',
        method: 'post',
        data: query
    });
};

//更新活动状态
export const UpStart = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/up/Start',
        method: 'post',
        data: query
    });
};

//更新审核状态
export const UpExamine = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/up/Examine',
        method: 'post',
        data: query
    });
};

//新增画板编号
export const AddCanvas = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/Activity/addCanvas',
        method: 'post',
        data: query
    });
};