export default {
    columns: [
        {
            title: '机构/企业',
            dataIndex: 'CompanyName',
            key: 'CompanyName',
            width: 200
        },
        {
            title: '活动项目昵称',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: '申请管理员',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '申请管理员联系方式',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: '贡献值额度',
            dataIndex: 'ToNumber',
            key: 'ToNumber',
        },
        {
            title: 'logo图片',
            dataIndex: 'LogoImage',
            key: 'LogoImage',
            scopedSlots: {customRender: 'LogoImage'},
        },
        {
            title: '状态',
            dataIndex: 'InShow',
            key: 'InShow',
        },
        {
            title: '委员会数量',
            dataIndex: 'CommitteeNumber',
            key: 'CommitteeNumber',
        },
        {
            title: '申请时间',
            key: 'Time',
            dataIndex: 'Time',
            width: 100
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: {customRender: 'Operate'},
        },
    ]
}
