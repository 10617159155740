import request from '../../utils/request';

//查询管理员
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/AdminCompany/User/find',
        method: 'get',
        params: query
    });
};

//添加管理员
export const addUser = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/AdminCompany/User/add',
        method: 'post',
        data: query
    });
};

//删除管理员
export const deleteUser = query => {
    return request({
        url: process.env.VUE_APP_URL + `admin/AdminCompany/User/delete/${query}`,
        method: 'delete',
    });
};


