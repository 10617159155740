<template>
<div class="Nav">
  <div class="block" v-bind:class="{open:TClass=='作品列表'}" @click="switchClass('作品列表')">作品列表</div>
  <div class="block" v-bind:class="{open:TClass=='弹幕列表'}" @click="switchClass('弹幕列表')">弹幕列表</div>
  <div class="block" v-bind:class="{open:TClass=='评论列表'}" @click="switchClass('评论列表')">评论列表</div>
  <div class="block" v-bind:class="{open:TClass=='奖品彩蛋'}" @click="switchClass('奖品彩蛋')">奖品彩蛋</div>
  <div class="block" v-bind:class="{open:TClass=='效果图'}" @click="switchClass('效果图')">效果图</div>
  <div class="block" v-bind:class="{open:TClass=='坐标位置'}" @click="switchClass('坐标位置')">坐标位置</div>
</div>
</template>

<script>
export default {
  props: {
    TClass: {
      type: String
    }
  },
  methods: {
    switchClass(value) {
      if (value == this.TClass) {
        return
      }
      this.$parent.switchClass(value)
    }
  }
}
</script>

<style scoped lang="scss">
.Nav{
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  .block{
    display: inline-block;
    color: #666666;
    width: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
  }
  .open{
    color: #02a7f0;
  }
}
</style>