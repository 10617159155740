
export default {
    columns:[
        {
            title: '评论者',
            key: 'UserName',
            dataIndex: 'UserName',
        },
        {
            title: '作品昵称',
            key: 'WorkName',
            dataIndex: 'WorkName',
        },
        {
            title: '文本内容',
            key: 'Data',
            dataIndex: 'Data',
        },
        {
            title: '新增时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
