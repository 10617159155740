<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="topNav">
      <a-button class="search-btn" @click="openListAdd">添加活动</a-button>
      <a-button class="down" @click="downloadIamge" type="link">下载活动二维码</a-button>
      <a-button class="search-btn" @click="copy" type="link">复制活动网址</a-button>
      <a-popover title="二维码">
        <template slot="content">
          <img :src="qrImage" alt="" style="width: 100px;margin: 0 auto">
        </template>
        <a-button type="link">查看活动二维码</a-button>
      </a-popover>

    </div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
        <span slot="LogoImage" slot-scope="tags,record">
        <a-avatar :size="30" icon="user" shape="square" :src="record.LogoImage"/>
       </span>

      <span slot="Start" slot-scope="tags">
         <a-tag v-if="tags==1" color="green">活动已开启</a-tag>
         <a-tag v-if="tags==0" color="red">活动已关闭</a-tag>
      </span>


      <span slot="Operate" slot-scope="text,record">
        <a-button type="link" @click="GoToDetails(record.id)">管理</a-button>
      </span>
    </a-table>
    <add ref="add"></add>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/Activity/moble/indexTest";
import add from './com/add.vue'
import {fetchData} from "@/api/Admin/Activity";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      url: '',
      qrImage: '',
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {add},
  mounted() {
    this.getdata()
  },
  methods: {
    copy() {
      let text = this.url
      const t = document.createElement('textarea')
      t.nodeValue = text
      t.value = text
      document.body.appendChild(t)
      t.select()
      document.execCommand('copy');
      document.body.removeChild(t)
      this.$message.success('复制成功');
    },
    openListAdd() {
      this.$refs.add.open()
    },
    GoToDetails(value) {
      this.$router.push(`/Admin/Activity/Details?Id=${value}`)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
    },
    getdata() {
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      let CompanyID = this.$store.state.user.info.CompanyID


      fetchData({pageIndex, pageSize, CompanyID}).then(res => {
        this.loading = false
        let data = res.payload.data

        for (let i in data) {
          let time = data[i].NewTime
          let day = new Date(time);
          data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }

        this.pagination.total = res.payload.total
        this.data = data
        this.qrImage = res.payload.qrImage
        this.url = res.payload.url
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    },
    downloadIamge() {
      let url = this.qrImage
      var a = document.createElement("a"); // 生成一个a元素

      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = '活动二维码'; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}


.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.down {
  margin-right: 15px;
}
</style>
