
export default {
    columns:[
        {
            title: 'Logo',
            dataIndex: 'LogoImg',
            key: 'LogoImg',
            scopedSlots: { customRender: 'LogoImg' },
        },
        {
            title: '昵称',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '标语',
            dataIndex: 'ActivityName',
            key: 'ActivityName',
        },
        {
            title: '成立时间',
            key: 'Time',
            dataIndex: 'Time',
            width:100
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
