<template>
  <div class="ObjectDetails">
    <Main></Main>
    <Nav :TClass="TClass"></Nav>
    <Work v-if="TClass=='作品列表'" ref="Work"></Work>
    <Comment v-if="TClass=='弹幕列表'" ref="Comment"></Comment>
    <Evaluate v-if="TClass=='评论列表'" ref="Evaluate"></Evaluate>
    <Prize v-if="TClass=='奖品彩蛋'" ref="Prize"></Prize>
    <MapEffect v-if="TClass=='效果图'"></MapEffect>
  </div>
</template>

<script>
import Main from '@/pages/Admin/pages/ActivityDetails/Com/Main/index.vue'
import Nav from '@/pages/Admin/pages/ActivityDetails/Com/Nav.vue'

import Work from '@/pages/Admin/pages/ActivityDetails/Com/Work/index.vue'
import Comment from '@/pages/Admin/pages/ActivityDetails/Com/Comment/index.vue'
import Evaluate from '@/pages/Admin/pages/ActivityDetails/Com/Evaluate/index.vue'
import Prize from '@/pages/Admin/pages/ActivityDetails/Com/Prize/index.vue'
import MapEffect from '@/pages/Admin/pages/ActivityDetails/Com/MapEffect/index.vue'

export default {
  data() {
    return {
      TClass: '作品列表'
    }
  },
  components: {Main, Nav, Work, Evaluate, Prize, Comment,MapEffect},
  methods: {
    switchClass(value) {
      this.TClass = value
    }
  }
}
</script>

<style scoped lang="scss">


</style>