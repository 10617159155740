//测试权限
export const RouterData = () => {
    return[
        {
            icon:'line-chart',
            title: '数据大板' ,
            item_link:'/Visualization'
        },
        {
            icon:'unordered-list',
            title: '总管理' ,
            item_link:'/Enterprise'
        },
        {
            icon:'flag',
            title: '企业管理',
            item_link:'/AdminCompany'
        },
        {
            icon:'home',
            title: '活动管理',
            item_link:'/Admin'
        },
        {
            icon:'trophy',
            title: '奖品管理',
            item_link:'/Prize'
        },
        {
            icon:'reconciliation',
            title: '审核管理',
            item_link:'/Examine'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}

//企业管理权限
export const AdminCompanyRouterData = () => {
    return[
        {
            icon:'line-chart',
            title: '数据大板' ,
            item_link:'/Visualization'
        },
        {
            icon:'flag',
            title: '企业管理',
            item_link:'/AdminCompany'
        },
        {
            icon:'home',
            title: '活动管理',
            item_link:'/Admin'
        },
        {
            icon:'reconciliation',
            title: '审核管理',
            item_link:'/Examine'
        },
        {
            icon:'trophy',
            title: '奖品管理',
            item_link:'/Prize'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}

//普通管理员权限
export const AdminRouterData = () => {
    return[
        {
            icon:'line-chart',
            title: '数据大板' ,
            item_link:'/Visualization'
        },
        {
            icon:'reconciliation',
            title: '审核管理',
            item_link:'/Examine'
        },
        {
            icon:'trophy',
            title: '奖品管理',
            item_link:'/Prize'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}
