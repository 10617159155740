<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="nav">
<!--      <span>搜索条件：</span>-->
<!--      <a-select default-value="用户姓名" style="margin-right: 15px;width: 120px" @change="handleChange">-->
<!--        <a-select-option value="用户姓名">用户姓名</a-select-option>-->
<!--        <a-select-option value="手机号码">手机号码</a-select-option>-->
<!--      </a-select>-->
<!--      <a-input-search :placeholder="'搜索'+typeSearch" enter-button @search="onSearch" class="search" v-model="search"/>-->
<!--      <a-button type="primary" class="resetting" @click="resetting">-->
<!--        重置-->
<!--      </a-button>-->
<!--      <a-button type="primary" class="resetting" @click="exportUser" :loading="loadingFile">-->
<!--        导出用户数据-->
<!--      </a-button>-->
      <!--      <a-button type="primary" class="resetting" @click="upUserCompanyInfo" :loading="loadingFile">-->
      <!--        刷新用户索引-->
      <!--      </a-button>-->
      <!--      <a-button type="primary" class="resetting" @click="test">-->
      <!--        测试生成文件-->
      <!--      </a-button>-->

    </div>
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             class="table"
             :rowKey="(record, index) => { return index }"
             :loading="loading">
        <span slot="Avatar" slot-scope="tags,record">
        <a-avatar :size="30" icon="user" shape="square" :src="record.Avatar"/>
       </span>
      <!--      <span slot="Operate" slot-scope="tags,record">-->
      <!--        <a-button @click="openUserDetails(record.id)" type="link">贡献值详情</a-button>-->
      <!--      </span>-->
    </a-table>
    <UserDetails ref="UserDetails"></UserDetails>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/User/moble/indexTest";
import UserDetails from './com/UserDetails.vue'
import {ExportUser, fetchAccurate, fetchData} from "@/api/Admin/User";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      loadingFile: false,
      data: [],
      classSearch: false,
      typeSearch: '用户姓名',
      search: '',
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      }
    }
  },
  components: {UserDetails},
  mounted() {
    this.getdata()
  },
  methods: {
    //导出用户数据
    exportUser() {
      let CompanyID = this.$store.state.user.info.CompanyID
      this.loadingFile = true
      ExportUser({CompanyID}).then(res => {
        this.loadingFile = false
        let payload = res.payload

        let files = process.env.VUE_APP_URL + payload
        window.open(files)

        this.$notification['success']({
          message: '系统',
          description:
              '导出成功',
        });
      })
    },
    //搜索模式（姓名、手机号码）
    handleChange(value) {
      this.typeSearch = value
    },
    //搜索
    onSearch() {
      let search = this.search
      let typeSearch = this.typeSearch
      if (!search) {
        this.$notification['error']({
          message: '系统',
          description:
              '请输入' + typeSearch,
        });
        return
      }
      this.pagination.current = 1
      this.pagination.total = 0
      this.classSearch = true
      this.getdataSearch()
    },
    openUserDetails(id) {
      this.$refs.UserDetails.open(id)
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      if (this.classSearch) {
        this.getdataSearch()
      } else {
        this.getdata()
      }
    },
    getdataSearch() {
      let search = this.search
      let skip = this.pagination.current
      let typeSearch = this.typeSearch
      let CompanyID = this.$store.state.user.info.CompanyID
      this.loading = true
      this.data = []
      fetchAccurate({skip, data: search, typeSearch, CompanyID}).then(res => {
        this.loading = false
        let payload = res.payload
        let data = payload.data
        for (let i in data) {
          let time = data[i].AddTime
          let day = new Date(time);
          data[i].AddTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        }
        this.data = data
        this.pagination.total = payload.total
      })
    },
    getdata() {
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      let CompanyID = this.$store.state.user.info.CompanyID
      this.data = []
      fetchData({pageIndex, pageSize, CompanyID}).then(res => {
        this.loading = false
        let data = res.payload.data
        let Total = res.payload.total
        for (let i in data) {
          let time = data[i].Newtime
          let day = new Date(time);
          data[i].Newtime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

          let times = data[i].LoginTime
          let days = new Date(times);
          data[i].LoginTime = days.getFullYear() + '-' + (days.getMonth() + 1) + '-' + days.getDate()
        }
        this.pagination.total = Total
        this.data = data
      })
    },
    getSumRegexData(data) {
      this.loading = false
      this.data = data
      this.pagination.current = 1
      this.pagination.total = 0
    },
    getRegexData() {
      this.loading = true
    },
    resetting() {
      this.search = ''
      this.classSearch = false
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 900px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }

  .table {
    width: 98%;
    height: 800px;
    margin: 0 auto;
  }
}

.resetting {
  margin-left: 15px;
}

.nav {
  width: 98%;
  margin: 0 auto;

  .search {
    width: 400px;
  }
}

.Token {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  position: relative;

  .img {
    background-image: url("../../../../assets/Token.png");
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 5px;
    display: inline-block;
  }
}
</style>
