<template>
  <div>
    <a-modal
        title="添加机构/企业管理员"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="机构/企业">
          <a-select
              v-decorator="[
                'CompanyID',
              {  rules: [{ required: true, message: '请选择机构/企业' }] },
          ]">
            <a-select-option :value="item.id" v-for="(item,inedx) in Company" :key="inedx">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
              placeholder="请输入昵称"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请输入姓名' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input
              placeholder="请输入手机号码"
              v-decorator="[
                                'Phone',
                                {  rules: [{ required: true, message: '请输入手机号码' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password
              placeholder="请输入密码"
              v-decorator="[
                            'Password',
                            {  rules: [{ required: true, message: '请输入密码' }] }
                        ]">
            >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请输入确认密码"
              v-decorator="[
                            'Passwords',
                            {  rules: [{ required: true, message: '请输入确认密码' }] }
                        ]">
            >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
        <a-form-item label="权限">
          <a-select
              v-decorator="[
                'Jurisdiction',
              {  rules: [{ required: true, message: '请选择权限' }] },
          ]">
            <a-select-option value="企业管理员">企业管理员</a-select-option>
            <a-select-option value="普通管理员">普通管理员</a-select-option>
          </a-select>
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {fetchDataCompany, addData} from "@/api/Enterprise/AdminUser";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      Company: [],
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
      this.findCompany()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Name = values.Name
          let ActivityName = values.ActivityName
          let CompanyID = values.CompanyID
          let Phone = values.Phone
          let Password = values.Password
          let Passwords = values.Passwords
          let Jurisdiction = values.Jurisdiction

          if (Password != Passwords) {
            this.$message.error('密码与确认密码有误');
            return;
          }


          this.confirmLoading = true;
          addData({CompanyID, Name, Phone, Password, Passwords, ActivityName, Jurisdiction}).then(res => {
            this.confirmLoading = false;
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.$parent.getdata()
              this.visible = false;
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
    findCompany() {
      fetchDataCompany().then(res => {
        this.Company = res.payload
      })
    }
  },
};
</script>
