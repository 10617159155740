import request from '../../utils/request';

//查询机构
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/Company/find',
        method: 'get',
        params: query
    });
};


//添加机构
export const addData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/Company/add',
        method: 'post',
        data: query
    });
};

//删除机构
export const deleteData = query => {
    return request({
        url: process.env.VUE_APP_URL + `admin/Enterprise/Company/delete/${query}`,
        method: 'delete',
    });
};


