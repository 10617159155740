<template>
  <div class="Object">
    <div class="topBlock"></div>
    <div class="main">
      <a-upload
          :headers="{
                  'X-Requested-With':null
              }"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="ImageUrl"
          @change="AddImage"
          class="file"
      >
        <img v-if="Avatar" :src="Avatar" alt="avatar" class="avatar"/>
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'"/>
          <div class="ant-upload-text">
            上传头像
          </div>
        </div>
      </a-upload>
      <div class="name">{{ info.Name }}</div>
      <div class="jurisdiction">角色权限：{{ info.Jurisdiction }}</div>
      <div class="company">所属企业：{{ info.CompanyName }}</div>
      <div class="companyNameID">社会信用代码：{{ info.CompanyNameID }}</div>
      <div class="companyName">企业管理员：{{ info.CompanyUser }}</div>
    </div>
  </div>
</template>

<script>
import {UpAvatar} from '@/api/SetUp/Info'

export default {
  data() {
    return {
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      Avatar: '',
      loading: false,
      info: this.$store.state.user.info
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    init() {
      let Avatar = this.info.Avatar
      this.Avatar = Avatar
    },
    AddImage(info) {
      let id = this.info.UserID
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload

          UpAvatar({id, Avatar: url}).then(res => {
            if (res.errorCode == 200) {
              this.Avatar = url
              this.$store.state.user.info.Avatar = url
              this.$message.success(res.payload);
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .main {
    width: 98%;
    height: 200px;
    border-bottom: 1px solid #e4e4e4;
    margin: 0 auto;
    position: relative;

    .file {
      position: absolute;
      left: 50px;
      top: 50px;
    }

    .avatar {
      border-radius: 60px;
      width: 50px;
      height: 50px;
    }

    .name {
      position: absolute;
      left: 200px;
      top: 50px;
      font-size: 20px;
      font-weight: bold;
    }

    .jurisdiction {
      position: absolute;
      left: 200px;
      top: 100px;
    }

    .company {
      position: absolute;
      left: 200px;
      top: 130px;
    }

    .companyNameID {
      position: absolute;
      left: 500px;
      top: 100px;
    }

    .companyName {
      position: absolute;
      left: 500px;
      top: 130px;
    }
  }
}

</style>
