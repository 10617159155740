
export default {
    columns:[
        {
            title: '头像',
            dataIndex: 'Avatar',
            key: 'Avatar',
            scopedSlots: { customRender: 'Avatar' },
        },
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号码',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: '权限',
            key: 'Jurisdiction',
            dataIndex: 'Jurisdiction',
        },
        {
            title: '添加时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
