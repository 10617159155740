import request from '../../utils/request';

//查询机构
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineObject/find',
        method: 'get',
        params: query
    });
};


export const passObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineObject/passObject',
        method: 'post',
        data: query
    });
};

export const outObject = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineObject/outObject',
        method: 'post',
        data: query
    });
};

