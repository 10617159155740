
export default {
    columns:[
        {
            title: '活动编号',
            dataIndex: 'TitleNumber',
            key: 'TitleNumber',
        },
        {
            title: '画板编号',
            key: 'CanvasNumber',
            dataIndex: 'CanvasNumber',
        },
        {
            title: '活动主题',
            key: 'Title',
            dataIndex: 'Title',
        },
        {
            title: '状态',
            key: 'Start',
            dataIndex: 'Start',
            scopedSlots: { customRender: 'Start' },
        },
        {
            title: '新增时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
