import request from '../../../utils/request';

//查询活动开启并获取活动ID
export const findActivity = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/find/Activity',
        method: 'get',
        params: query
    });
};
//查询奖品彩蛋
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/find',
        method: 'get',
        params: query
    });
};

// 添加奖品彩蛋
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/add',
        method: 'post',
        data: query
    });
};

// 编辑奖品彩蛋
export const edit = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/edit',
        method: 'post',
        data: query
    });
};

// 删除奖品彩蛋
export const deletePrize = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/delete',
        method: 'post',
        data: query
    });
};


//导出用户中奖数据
export const exportPize = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/exportUser',
        method: 'post',
        data: query,
        timeout:1000000
    });
};

//核销奖品
export const writeOffPrze = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Prize/writeOff',
        method: 'post',
        data: query
    });
};


export const serverFileUrl = () => {
    return process.env.VUE_APP_URL
};