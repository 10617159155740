<template>
  <div>
    <a-modal
        title="添加活动项目"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="活动主题">
          <a-input
              placeholder="请输入活动主题"
              v-decorator="[
                            'Title',
                            {  rules: [{ required: true, message: '请输入活动主题' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="活动编号">
          <a-input
              placeholder="请输入活动编号"
              v-decorator="[
                            'TitleNumber',
                            {  rules: [{ required: true, message: '请输入活动编号' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="开屏页">
          <img :src="OpenImg" alt="" v-if="OpenImg" style="width: 200px;display: block">
          <a-upload
              name="file"
              :multiple="false"
              @change="AddImageOpenImg"
              :action="ImageUrl"
              :show-upload-list="false"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传开屏页
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item label="画板背景图">
          <img :src="BgImg" alt="" v-if="BgImg" style="width: 200px;display: block">
          <a-upload
              name="file"
              :multiple="false"
              @change="AddImageBgImg"
              :action="ImageUrl"
              :show-upload-list="false"
              :headers="{
                  'X-Requested-With':null
              }"
          >
            <a-button>
              <a-icon type="upload"/>
              上传画板背景图
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item label="开始日期">
          <a-date-picker
              v-decorator="[
                            'TimeStart',
                            {  rules: [{ required: true, message: '请选择开始日期' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="结束日期">
          <a-date-picker
              v-decorator="[
                            'TimeEnd',
                            {  rules: [{ required: true, message: '请选择结束日期' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="画板x数量">
          <a-input-number :min="1"
                          v-decorator="[
                            'CanvasNumberX',
                            {  rules: [{ required: true, message: '请输入画板x数量' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="画板Y数量">
          <a-input-number :min="1"
                          v-decorator="[
                            'CanvasNumberY',
                            {  rules: [{ required: true, message: '请输入画板Y数量' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {addActivity} from "@/api/Admin/Activity";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      ImageUrl: process.env.VUE_APP_URL + 'admin/Activity/image',
      //开屏图
      OpenImg: '',
      //画板背景图
      BgImg: ''
    };
  },
  components: {},
  methods: {
    AddImageOpenImg(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.OpenImg = url
          this.$message.success('上传成功');
        } else {
          this.$message.error(response.errorMsg);
        }
      }
    },
    AddImageBgImg(info) {
      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          let url = response.payload
          this.BgImg = url
          this.$message.success('上传成功');
        } else {
          this.$message.error(response.errorMsg);
        }
      }
    },
    //打开
    open() {
      this.visible = true;
      this.User = []
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.OpenImg) {
            this.$message.error('未添加开屏页');
            return
          }
          if (!this.BgImg) {
            this.$message.error('未添加画板背景图');
            return
          }
          let CompanyID = this.$store.state.user.info.CompanyID
          let OpenImg = this.OpenImg
          let BgImg = this.BgImg
          let CanvasNumberX = values.CanvasNumberX
          let CanvasNumberY = values.CanvasNumberY
          let TimeEnd = values.TimeEnd
          let TimeStart = values.TimeStart
          let Title = values.Title
          let TitleNumber = values.TitleNumber

          addActivity({
            OpenImg,
            Title,
            BgImg,
            CanvasNumberX,
            CanvasNumberY,
            CompanyID,
            TimeEnd,
            TimeStart,
            TitleNumber
          }).then(res => {
            this.confirmLoading = false;
            if (res.errorCode == 200) {
              this.visible = false;
              this.$message.success(res.payload);
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })

        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.$parent.getdata()
    },
  },
};
</script>

<style lang="scss">
.button {
  display: block;
}
</style>