<template>
  <a-spin :spinning="load">
    <div class="Work">
      <div class="title">今日作品增长量</div>
      <div id="container"></div>
    </div>
  </a-spin>
</template>

<script>
import {Line} from '@antv/g2plot';
import {newDateWork} from "@/api/Visualization";

export default {
  data() {
    return {
      load: false
    }
  },
  mounted() {
    let CompanyID = this.$store.state.user.info.CompanyID
    this.load = true
    newDateWork({CompanyID}).then(res => {
      this.load = false
      if (res.errorCode == 200) {
        let payload = res.payload

        if (payload.start == '正常') {
          let data = payload.data

          const line = new Line('container', {
            data,
            xField: '时间',
            yField: '新增作品量',
            smooth: true,
          });

          line.render();
        } else {
          this.$message.error(payload.start);
        }
      } else {
        this.$message.error(res.errorMsg);
      }
    })


  }
}

</script>

<style scoped lang="scss">
.Work {
  width: 90%;
  height: 600px;
  margin: 0 auto;

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
</style>