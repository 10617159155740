<template>
  <div>
    <a-modal
        title="修改密码"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :model="form">
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-model="form.newPassword"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-model="form.confirmPassword"
          />
        </a-form-item>
        <a-form-item label="手机验证码">
          <a-input-search
              v-model="form.Code"
              :loading="loadingCode"
              placeholder="请输入验证码"
              :enter-button="StartText"
              @search="setCode"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {modifyPow, sendingCode} from "@/api/SetUp/Secure";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        Code: ''
      },
      Start: false,
      StartText: '发送验证码',
      Time: 0,
      loadingCode: false
    };
  },
  methods: {
    //发送验证码
    setCode() {
      let time = this.Time

      if (time == 0) {
        let info = this.$store.state.user.info
        let Phone = info.Phone
        this.loadingCode = true

        sendingCode({Phone}).then(res => {
          this.loadingCode = false
          if (res.errorCode == 200) {
            this.$message.success(res.payload);
            this.SetTime()
          } else {
            this.$message.error(res.errorMsg);
          }
        })
      }
    },
    //打开
    open() {
      this.visible = true;
    },
    handleOk() {

      let Code = this.form.Code
      let info = this.$store.state.user.info
      let Phone = info.Phone
      let newPassword = this.form.newPassword
      let confirmPassword = this.form.confirmPassword
      let id = info.UserID

      this.confirmLoading = true;

      modifyPow({id, Phone, Code, newPassword, confirmPassword}).then(res => {
        this.confirmLoading = false
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.handleCancel()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.form.Code = ''
      this.form.newPassword = ''
      this.form.confirmPassword = ''
    },
    //开始计时
    SetTime() {
      this.Time = 60
      this.CodeTime = setInterval(() => {
        let times = this.Time
        times = times - 1
        if (times == 0) {
          clearInterval(this.CodeTime)
          this.StartText = '获取验证码'
          this.Time = 0
          return
        }
        this.Time = times
        this.StartText = times + '秒再次发送'
      }, 1000)
    }
  },
};
</script>
