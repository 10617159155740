<template>
  <div class="Distribution">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data" :pagination="pagination" @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
        <span slot="InShow" slot-scope="tags">
          <a-badge v-if="tags=='未审核'" status="warning" text="未审核"/>
          <a-badge v-if="tags=='违规'" status="error" text="违规"/>
          <a-badge v-if="tags=='正常'" status="success" text="正常"/>
        </span>
      <span slot="ImageUrl" slot-scope="text">
        <img :src="text" alt="" style="width: 100px" @click="openImage(text)" class="ImageUrl">
      </span>
      <span slot="Operate" slot-scope="text,record">
         <a-button type="link" html-type="submit" @click="mark(record.id,'违规')" v-if="record.InShow=='正常'||record.InShow=='未审核'" class="red">标记违规</a-button>
         <a-button type="link" html-type="submit" @click="mark(record.id,'正常')" v-if="record.InShow=='违规'||record.InShow=='未审核'" class="green">标记正常</a-button>
      </span>
    </a-table>
    <LightBox :media="media" :closable="false" v-if="lightboxVisible"></LightBox>
    <div class="close" v-if="lightboxVisible" @click="closeImage">
      <img src="../../../../../../../public/images/close.png" alt="">
    </div>
  </div>
</template>

<script>
import indexTest from "@/pages/Admin/pages/ActivityDetails/Com/Work/moble/indexTest";
import {find} from "@/api/Admin/ActivityDetails/Work";
import {setMark} from "@/api/Examine/Work";
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      media: [],
      lightboxVisible: false,
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    openImage(img) {
      this.media = [
        {
          thumb: img,
          src: img
        }
      ]
      this.lightboxVisible = true
    },
    closeImage(){
      this.lightboxVisible = false
    },
    mark(id, InShow) {
      setMark({id, InShow}).then(res=>{
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.getdata()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.loading = true
      let CompanyID = this.$store.state.user.info.CompanyID
      let ActivityID = this.$route.query.Id
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      this.data =[]

      find({CompanyID, ActivityID, pageIndex, pageSize}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          let total = res.payload.total

          for (let i in data) {
            let time = data[i].Time
            let day = new Date(time);
            data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }

          this.data = data
          this.pagination.total = total
        } else {
          this.$message.error(res.errorMsg);
        }
      })

    },
    resetting() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.getdata()
    }
  },
  components:{LightBox}
}
</script>

<style scoped lang="scss">

.Distribution {
  width: 100%;
  height: 750px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 10px;
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }
}

.search {
  margin-bottom: 15px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.ImageUrl{
  cursor: pointer;
}

.close {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 50px;
  top: 50px;
  z-index: 10000;
  cursor: pointer;
  transition: transform 0.3s ease;

  img {
    width: 100%;
    height: 100%
  }
}

.close:hover {
  transform: rotate(90deg); /* 当鼠标悬停时应用旋转变换 */
}
</style>
