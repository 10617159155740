import request from '../../utils/request';

//查询数据
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineCompany/find',
        method: 'get',
        params: query
    });
};

//审批未通过
export const fail = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineCompany/fail',
        method: 'post',
        data: query
    });
};

//审批通过
export const pass = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Enterprise/ExamineCompany/pass',
        method: 'post',
        data: query
    });
};

