<template>
  <div>
    <a-modal
        :title="isEdit ? '编辑彩蛋奖品' : '添加彩蛋奖品'"
        okText="提交"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="500px"
    >
      <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="奖品标题">
          <a-input
              placeholder="请输入奖品标题"
              v-decorator="[
                                    'Title',
                                    {  rules: [{ required: true, message: '请输入奖品标题'}], initialValue: isEdit ? commodityDetail.Title : '' },
                                ]"
          />
        </a-form-item>
        <a-form-item label="中奖图片">
          <a-upload
              :headers="{
                  'X-Requested-With':null
              }"
              name="file"
              list-type="picture-card"
              :show-upload-list="false"
              :action="ImageUrl"
              @change="upBgImg"
              class="avatar-uploader"
          >
            <img v-if="Image" :src="Image" alt="" style="width: 100px;display: block">
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="作品数触发点">
          <a-input-number style="width: 100%;"
                          placeholder="请输入作品数触发点"
                          v-decorator="[
                                    'WorkNumber',
                                    {  rules: [{ required: true, message: '请输入作品数触发点' }], initialValue: isEdit ? commodityDetail.WorkNumber : 1 },
                                ]"
          />
        </a-form-item>
        <a-form-item label="兑换须知">
          <a-textarea
              placeholder="请输入兑换须知"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                                'Notice',
                                {  rules: [{ required: true, message: '请输入兑换须知' }], initialValue: isEdit ? commodityDetail.Notice : '' },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {add, edit} from "@/api/Admin/ActivityDetails/Prize";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      Image: '',


      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      //导入商品数据
      commodityDetail: {},
      ImageUrl: process.env.VUE_APP_URL + 'admin/Activity/image'
    };
  },
  methods: {
    upBgImg(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let Url = response.payload
          this.Image = Url
        }
      }
    },
    open(commodity) {
      this.visible = true
      this.form.resetFields()
      this.Image = commodity.Image ? commodity.Image : ''
      this.commodityDetail = commodity;
    },
    handleOk() {
      let Image = this.Image

      if (!Image) {
        this.$message.error('缺少中奖图');
        return
      }

      this.form.validateFields(async (err, values) => {
        if (!err) {
          let ActivityID = this.$route.query.Id
          values.ActivityID = ActivityID
          values.Image = Image
          if (this.$props.isEdit) {
            let id = this.commodityDetail.id
            edit({id, ...values}).then(res => {
              if (res.errorCode == 200) {
                this.visible = false;
                this.$message.success(res.payload);
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          } else {
            add({...values}).then(res => {
              if (res.errorCode == 200) {
                this.visible = false;
                this.$message.success(res.payload);
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          }
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
  },
};
</script>

<style lang="scss">

</style>