
export default {
    columns:[
        {
            title: '奖品标题',
            key: 'Title',
            dataIndex: 'Title',
        },
        {
            title: '作品数量触发点',
            key: 'WorkNumber',
            dataIndex: 'WorkNumber',
        },
        {
            title: '奖品海报',
            key: 'Image',
            dataIndex: 'Image',
            scopedSlots: { customRender: 'Image' },
        },
        {
            title: '添加时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '中奖时间',
            key: 'UpTime',
            dataIndex: 'UpTime',
        },
        {
            title: '状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
        },
    ]
}
