import request from '../../../utils/request';

//查询作品
export const find = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Work/find',
        method: 'get',
        params: query
    });
};

//查询作品
export const FindActivity = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Admin/ActivityDetails/Work/Activity/find',
        method: 'get',
        params: query
    });
};


//查询所有作品数据（根据画板编号）
export const MapEffectFind = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/ActivityDetails/Work/MapEffect/find',
        method: 'get',
        params: query
    });
};


//预览图  获取图片编码base64格式
export const Canvasbase64 = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/ActivityDetails/Work/Canvasbase64',
        method: 'post',
        data: query,
        timeout: 500000
    });
};
